cs.ns("app.ui.composite.login");
app.ui.composite.login.ctrl = cs.clazz({
    extend:   app.ui.composite.abstract.modal.ctrl,
    dynamics: {},
    protos:   {
        create () {
            this.base(app.ui.composite.login.model, app.ui.composite.login.view);
        },

        acceptModal () {
            let username = this.model.value('data:username')
            let password = this.model.value('data:password', '')
            let token    = this.model.value('data:token', '')
            this.model.value('data:errorMessage', '');

            if ((username && password) || token) {
                this.publishEventToParent("login", username, password, token, this.acceptCallback.bind(this), (result) => {
                    let msg = ''
                    if (result.message) {
                        msg += i18next.t(`appLogin.alert.${result.message}`)
                    }
                    if (result.error && username !== '' && msg.length === 0)
                        msg += i18next.t(`appLogin.alert.${result.error}`)
                    this.model.value('data:errorMessage', msg);
                });
            } else {
                this.model.value('data:errorMessage', i18next.t("appLogin.alert.noInput"));
                this.model.value("command:focus", true);
            }
        }

    }
});